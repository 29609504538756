import Vue from 'vue'
import App from './App.vue'
import clipboard from 'vue-clipboard2'
import plugin from '@/canvas/plugin/index'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import '@/assets/iconfont/iconfont.css'

import '@/styles/index.less'
import '@/styles/quill.theme.less'

import '@/canvas/plugin/monitor'
import { isPreload } from '@/utils'


Vue.use(clipboard)
Vue.use(plugin)

Vue.config.productionTip = false

if (isPreload()) {
  // 预加载不上报Ï
  Vue.prototype.$reportUI = () => {}
  window.reportConfig.sample = 0
}

new Vue({
  render: (h) => h(App)
}).$mount('#app')
