<template>
  <div v-if="page.blocks" id="app" :style="[appStyle]">
    <!-- 无吸顶视频时，置顶下载六要素，设置1001保证优先级比其他置顶组件高 -->
    <template v-if="isTemplateH5">
      <div v-for="(block, index) in blocks" :key="index" class="auto-block">
        <template v-if="block.name !== 'fixed-block'">
          <div
            v-for="component in block.components"
            :id="component.id"
            :key="component.id"
            class="auto-layout"
          >
            <component
              v-show="templateH5FormLoaded"
              :is="getComponent(component.name)"
              v-impr="component.id"
              :component="component"
              :download="download"
              :impr-data="getReportInfo(component)"
              :action="action"
              context="prod"
              @form-loaded="isTemplateH5FormLoaded"
            >
            </component>
          </div>
        </template>
      </div>
    </template>
    <template v-else>
      <download-six-elements
        v-if="!fixedTopNeedPadding && supportSixElements"
        ref="sixElements"
        style="z-index: 1001"
        @change="changeShowSixElements"
      ></download-six-elements>
      <div class="page-container">
        <!-- 置顶组件: 正常覆盖 -->
        <div
          v-if="fixedTop"
          class="fixed-top"
          :style="fixedTopStyle"
          @click="heatMapReport(1, $event)"
        >
          <div
            v-if="fixedTopNormal"
            :style="
              layoutStyles(fixedTopNormal.data.layout, fixedTopNormal.fixed, {
                isFixedTopNormal: true
              })
            "
            class="fixed-layout"
          >
            <component
              :is="getComponent(fixedTopNormal.name)"
              v-impr="fixedTopNormal.id"
              :component="fixedTopNormal"
              :download="download"
              :impr-data="getReportInfo(fixedTopNormal)"
              context="prod"
            >
            </component>
          </div>
        </div>
        <!-- 置底组件: 用户配置的组件 -->
        <div v-if="fixedBottom" class="fixed-bottom" @click="heatMapReport(2, $event)">
          <div
            :style="{
              ...layoutStyles(fixedBottom.data.layout, fixedBottom.fixed),
              ...bottomExtra.fixedBottomBodyStyles
            }"
            class="fixed-layout"
          >
            <component
              :is="getComponent(fixedBottom.name)"
              v-impr="fixedBottom.id"
              :component="fixedBottom"
              :download="download"
              :impr-data="getReportInfo(fixedBottom)"
              context="prod"
            >
            </component>
          </div>
        </div>
        <!-- 置底组件: 我们帮用户添加的组件 -->
        <div class="fixed-bottom" @click="heatMapReport(2, $event)">
          <div class="fixed-layout" style="bottom: 0; left: 0; width: 100%">
            <div v-if="hasDownloadButtonBottom" class="download-button-wrapper">
              <download-button
                :component="autoFillDownloadComp"
                :download="download"
                :is-auto="true"
              ></download-button>
            </div>
            <location-button-bottom
              v-if="hasLocationButtonBottom"
              :component="analysis.firstForm"
              :first-form-data="firstFormData"
            />
            <privacy v-if="analysis.hasForm" :is-get-extra-data="isGetExtraData"></privacy>
            <div v-if="isLongIOS" class="ios-safe"></div>
          </div>
        </div>
        <!-- 置左组件 -->
        <div v-if="fixedLeft" class="fixed-left">
          <div :style="layoutStyles(fixedLeft.data.layout, fixedLeft.fixed)" class="fixed-layout">
            <component
              :is="getComponent(fixedLeft.name)"
              v-impr="fixedLeft.id"
              :component="fixedLeft"
              :download="download"
              :impr-data="getReportInfo(fixedLeft)"
              context="prod"
            >
            </component>
          </div>
        </div>
        <!-- 置右组件 -->
        <div v-if="fixedRight" class="fixed-right">
          <div :style="layoutStyles(fixedRight.data.layout, fixedRight.fixed)" class="fixed-layout">
            <component
              :is="getComponent(fixedRight.name)"
              v-impr="fixedRight.id"
              :component="fixedRight"
              :download="download"
              :impr-data="getReportInfo(fixedRight)"
              context="prod"
            >
            </component>
          </div>
        </div>
        <!-- 置顶组件: 占据位置 -->
        <div
          v-if="fixedTopNeedPadding"
          class="fixed-top-need-padding"
          @click="heatMapReport(1, $event)"
        >
          <div
            :class="{ 'fixed-top': !isPc }"
            :style="{
              ...fixedTopPaddingStyle,
              ...fixedTopStyle,
              ...(isPc ? { position: 'relative' } : {})
            }"
          >
            <component
              :is="getComponent(fixedTopNeedPadding.name)"
              v-impr="fixedTopNeedPadding.id"
              :component="fixedTopNeedPadding"
              :download="download"
              :impr-data="getReportInfo(fixedTopNeedPadding)"
              context="prod"
            >
            </component>
          </div>
          <div v-if="!isPc" :style="fixedTopPaddingStyle"></div>
        </div>
        <!-- 有吸顶视频时，下载六要素在吸顶视频下面 -->
        <download-six-elements
          v-if="fixedTopNeedPadding && supportSixElements"
          ref="sixElements"
          style="z-index: 1"
          @change="changeShowSixElements"
        ></download-six-elements>
        <!-- 普通平铺组件 -->
        <div
          v-for="(block, index) in blocks"
          :key="index"
          :style="[blockStyle(block), abTestColor]"
          class="block"
          @click="heatMapReport(0, $event)"
        >
          <div
            v-for="component in block.components"
            :id="component.id"
            :key="component.id"
            :style="layoutStyles(component.data.layout, component.fixed)"
            class="layout"
          >
            <component
              :is="getComponent(component.name)"
              v-impr="component.id"
              :component="component"
              :download="download"
              :impr-data="getReportInfo(component)"
              :action="action"
              context="prod"
            >
            </component>
          </div>
        </div>
        <!-- 手动添加的置底组件，不能覆盖下面的内容 -->
        <div
          :style="{ height: length(fixedBottomPadding + bottomExtra.fixedBottomPadding) }"
        ></div>
        <!-- 特殊组件 -->
        <!-- 表单定位按钮组件: 右下角 -->
        <location-button
          :blocks="blocks"
          @show="hasLocationButtonRight = true"
          @click="heatMapReport(2, $event)"
        />
      </div>
      <!-- <i-loading v-if="loading" fix /> -->
      <div v-if="currentEnv" class="env-fixed" @click="heatMapReport(2, $event)">
        {{ currentEnv }}
      </div>
    </template>
    <div v-if="isPreload" class="pre-load-mask"></div>
  </div>
</template>
<script>
import qs from 'qs'
import { set, get, isUndefined } from 'lodash'
import { inBiliApp } from '@bilibili/js-bridge'
import elementResizeDetector from 'element-resize-detector'

import stylesMap from '@/canvas/stylesMapC'
import LocationButton from '@/canvas/components/form/LocationButton'
import {
  isLongIOS,
  webpSafe,
  isAndroid,
  getOffsetTop,
  getViewHeight,
  getFormButtonExpInfo,
  isPreview,
  isAd,
  getPageReportInfo,
  getPageReportCvrScrollInfo,
  callJSB,
  isIOS
} from '@/canvas/utils'
import {
  MINI_item_cvr_click,
  MINI_jsb_error,
  MINI_page_click,
  MINI_page_close,
  MINI_page_load_fail,
  MINI_page_load_success,
  MINI_page_rendered,
  MINI_page_slide,
  MINI_page_slide_cvr,
  MINI_web_cvr_up,
  MINI_work_wechat_id_fail,
  MINI_ios_app_preload_success,
  MINI_ios_app_preload_error,
  MINI_game_app_download_error,
  MINI_game_app_download_success,
  MINI_heatmap_click
} from '@/canvas/constants/reportEvents'
import mixin from '../mixin'
import Privacy from '@/canvas/components/form/Privacy'
import DownloadSixElements from '@/canvas/components/download/DownloadSixElements'
import defaultButtonData from '@/config/defaultButtonData'
import DownloadButton from '@/canvas/download-button/Component'
import {
  downloadStatus,
  errorEnum,
  errorMsgEnum,
  SUPPORT_WEB_DOWNLOAD_VERSION
} from '@/canvas/constants/download'
import LocationButtonBottom from '@/canvas/components/form/LocationBunttonBottom'
import { getEnv, getQuery, replaceUrl, isPreload, isPreRender } from '@/utils'
import {
  WARN_page_download_fail,
  WARN_page_load_fail,
  WARN_page_weixin_tackid_fail
} from '@/canvas/constants/warnEvents'
import { TouchUtils } from '@/canvas/touchUtils.ts'
// import comp from '../../../comp.json'

// 创意联投拼接视频，形同吸顶视频-横屏
const jointComps = ['joint-video-fixed-top']
// 这类置顶组件不覆盖其他组件，全局只能有一个
const needPaddingComps = ['top-landscape-video', 'top-portrait-video', ...jointComps]

const pageQuery = (window.location.search || '').substring(1)
const query = qs.parse(pageQuery) || {}
const pageId = query.pageId || ''
const EVENT_SOURCES = {
  NATURAL: 2, // 自然流量
  PREVIEW: 4 // 查看预览
}

export default {
  name: 'PageApp',
  components: {
    LocationButtonBottom,
    DownloadButton,
    Privacy,
    LocationButton,
    DownloadSixElements
  },
  mixins: [mixin],
  provide() {
    return {
      pageId,
      pageQuery
    }
  },
  data() {
    return {
      page: {
        blocks: []
      },
      loading: false,
      download: null,
      autoFillDownloadComp: {
        data: defaultButtonData // 改造成config
      },
      androidDownloadUrl: '',
      gameDownloadData: {},
      retryDownloadRegister: 0,
      // 第一个表单数据
      firstFormData: null,
      // 右下角是否含有表单定位按钮
      hasLocationButtonRight: false,
      // 是否是长屏IOS
      isLongIOS,
      experiments: {},
      analysis: {},
      analysisReport: {},
      currentEnv: '',
      showSixElements: false,
      showSixElementsAlways: false,
      appScrollHeight: 0, // 监控页面长度
      isExtraInfoLoaded: false,
      androidDownloadHandlerCallback: null,
      androidDownloadHandlerCallbackData: null,
      isPreload: isPreload(),
      isSetPageInfo: false,
      abTestColor: {
        filter: ''
      },
      isIosAppPreLoad: false,
      templateStyle: null,
      // 是否获取到实验数据
      isGetExtraData: false,
      touchUtils: null,
      templateH5FormLoaded: false
    }
  },
  computed: {
    fixedBottomPadding() {
      if (this.fixedBottom) {
        return this.fixedBottom.data.layout.height + this.fixedBottom.data.layout.top
      }
      return 0
    },
    fixedTopStyle() {
      return this.supportSixElements && this.showSixElementsAlways && this.showSixElements
        ? { position: 'absolute' }
        : { position: 'fixed' }
    },
    fixedBlock() {
      return this.page.blocks[0]
    },
    blocks() {
      return this.page.blocks.slice(1)
    },
    fixedTop() {
      return this.fixedBlock && this.fixedBlock.components.filter((c) => c.fixed === 'top')
    },
    fixedLeft() {
      return this.fixedBlock && this.fixedBlock.components.find((c) => c.fixed === 'left')
    },
    fixedRight() {
      return this.fixedBlock && this.fixedBlock.components.find((c) => c.fixed === 'right')
    },
    fixedBottom() {
      return this.fixedBlock && this.fixedBlock.components.find((c) => c.fixed === 'bottom')
    },
    fixedTopJoint() {
      return this.fixedTop && this.fixedTop.find((c) => jointComps.includes(c.name))
    },
    fixedTopNeedPadding() {
      return this.fixedTop && this.fixedTop.find((c) => needPaddingComps.includes(c.name))
    },
    fixedTopNormal() {
      return this.fixedTop && this.fixedTop.find((c) => !needPaddingComps.includes(c.name))
    },
    fixedTopPaddingStyle() {
      return (
        this.fixedTopNeedPadding && {
          height: this.length(this.fixedTopNeedPadding.data.layout.height)
        }
      )
    },
    hasDownloadButtonBottom() {
      // 置底下载组件（实验组） 1.支持下载 2.有下载组件 3.没有其他置底组件
      return (
        !!this.experiments?.h5_download_hit &&
        this.supportDownload &&
        this.analysis.hasDownload &&
        !this.fixedBottom &&
        get(this.autoFillDownloadComp, 'data.content.behavior.android.url')
      )
    },
    hasLocationButtonBottom() {
      let expRes = false

      const EXP_MAP = {
        BASE: 1,
        EXP_NORMAL: 2,
        EXP_PAGE_HEIGHT: 3
      }
      const formButtonAdd = window._globalExp?.form_button_add

      // 非客户端环境 或 请求jsb后无实验信息
      if (!inBiliApp || (isUndefined(formButtonAdd) && this.isExtraInfoLoaded)) {
        expRes = true
      } else if (formButtonAdd === EXP_MAP.EXP_NORMAL) {
        expRes = true
      } else if (formButtonAdd === EXP_MAP.EXP_PAGE_HEIGHT) {
        expRes =
          this.appScrollHeight >
          (document.documentElement.clientHeight *
            (window._globalExp?.form_button_view_limit || 0)) /
            100
      }

      const isFakePage = getQuery('is_gaoneng_fake')
      return (
        !isFakePage &&
        !!this.experiments?.form_button_hit &&
        expRes &&
        this.analysis.hasForm &&
        !this.hasDownloadButtonBottom &&
        !this.fixedBottom &&
        !this.hasLocationButtonRight
      )
    },
    bottomExtra() {
      let fixedBottomPadding = 0
      const fixedBottomBodyStyles = {
        marginBottom: 0
      }

      if (this.analysis.hasForm) {
        // 隐私协议高度
        const FORM_PRIVACY_HEIGHT = 30
        // 底部表单定位按钮高度
        const FORM_LOCATION_BUTTON = 44

        fixedBottomPadding += FORM_PRIVACY_HEIGHT
        if (this.hasLocationButtonBottom) {
          fixedBottomPadding += FORM_LOCATION_BUTTON
        }
      }

      if (this.hasDownloadButtonBottom) {
        const DOWNLOAD_BUTTON_HEIGHT = 60
        fixedBottomPadding += DOWNLOAD_BUTTON_HEIGHT
      }

      // IOS 安全距离 34px
      const IOS_SAFE_HEIGHT = isLongIOS ? 34 : 0
      if (fixedBottomPadding >= 0) {
        fixedBottomPadding += IOS_SAFE_HEIGHT
      }

      fixedBottomBodyStyles.marginBottom = fixedBottomPadding + 'px'

      return {
        fixedBottomPadding,
        fixedBottomBodyStyles
      }
    },
    supportDownload() {
      return this.build >= SUPPORT_WEB_DOWNLOAD_VERSION && this.model === 'android' && inBiliApp
    },
    action() {
      return {
        getFirstFormData: (id, data) => {
          const firstFormId = get(this.analysis, 'firstForm.data.form.id')
          if (firstFormId === id) {
            this.firstFormData = data
          }
        }
      }
    },
    supportSixElements() {
      // 需要等jsb加载结束
      return this.analysis.hasDownload && isAndroid && inBiliApp && this.isExtraInfoLoaded
    },
    isTemplateH5() {
      return +this.templateStyle === 501
    }
  },
  watch: {
    supportDownload(val) {
      if (val && this.androidDownloadHandlerCallback && this.androidDownloadHandlerCallbackData) {
        this.androidDownloadHandlerCallback(this.androidDownloadHandlerCallbackData)
      }
    }
  },
  mounted() {
    // this.page = (comp?.data?.config || [])[0] || {}
    // this.templateStyle = 501
    if (pageId && this.isSetPageInfo === false) {
      this.getPageRequest()
    }
    // this.refresh()
    this.detectPageSize()
  },
  created() {
    let isFrame = getQuery('is_gaoneng_iframe')
    if (isFrame) {
      document.documentElement.style.setProperty('scrollbar-width', 'none');
      console.log('document.documentElement.style', document.documentElement.style)
    }

    this.init()
    this.getModel(this.getlandingPageInfo)
    this.handleUrl()
    this.pageHideHandler = () => {
      if (!this.MINI_page_close) {
        this.MINI_page_close = true
        this.$reportUI(MINI_page_close, { scrollCount: getViewHeight() })
      }
    }
    this.clickHandler = (ev) => {
      this.$reportUI(MINI_page_click, {}, ev)
    }
    this.touchEndHandler = (ev) => {
      if (this.moving) {
        const e = ev.changedTouches[0] || {}
        this.$reportUI(
          MINI_page_slide,
          {
            ...getPageReportInfo()
          },
          e
        )
        this.$reportUI(
          MINI_page_slide_cvr,
          {
            ...getPageReportCvrScrollInfo()
          },
          e
        )
        this.moving = false
      }
      this.changeShowSixElements()
    }
    this.touchMoveHandler = () => {
      this.moving = true
    }
    this.scrollHandler = () => {
      // PC只记录第一次滑动
      if (!this.MINI_page_slide && this.isPc) {
        this.$reportUI(MINI_page_slide, {
          ...getPageReportInfo()
        })
        this.MINI_page_slide = true
      }
      if (!this.MINI_page_slide_cvr && this.isPc) {
        this.$reportUI(MINI_page_slide_cvr, {
          ...getPageReportCvrScrollInfo()
        })
      }
      this.changeShowSixElements()
    }
    window.addEventListener('pagehide', this.pageHideHandler)
    window.addEventListener('beforeunload', this.pageHideHandler)
    window.addEventListener('scroll', this.scrollHandler)
    // 考虑到vConsole交互穿透，所以只监听body
    document.body.addEventListener('click', this.clickHandler)
    document.body.addEventListener('touchmove', this.touchMoveHandler)
    document.body.addEventListener('touchend', this.touchEndHandler)
  },
  beforeDestroy() {
    window.removeEventListener('pagehide', this.pageHideHandler)
    window.removeEventListener('beforeunload', this.pageHideHandler)
    window.removeEventListener('scroll', this.scrollHandler)
    document.body.removeEventListener('click', this.clickHandler)
    document.body.removeEventListener('touchmove', this.touchMoveHandler)
    document.body.removeEventListener('touchend', this.touchEndHandler)
    // 移除键盘相关特殊处理交互
    if (this.touchUtils) {
      this.touchUtils.touchUnRegister()
    }
  },
  methods: {
    heatMapReport(type = 0, e) {
      this.$reportUI(MINI_heatmap_click, { type, page_id: pageId }, e)
    },
    init() {
      // 广告卡数据
      window.ad_cb = ''
      // 全局数据
      window._globalData = {}
      // 全局实验组
      window._globalExp = {}
      // 前卡数据
      window._extraData = {}
    },
    unInit() {
      this.androidDownloadHandlerCallback = null
      this.androidDownloadHandlerCallbackData = null
    },
    handleUrl() {
      let queries = {}
      if (isPreview()) {
        queries = {
          event_source_type: EVENT_SOURCES.PREVIEW,
          is_preview: 1
        }
      } else if (!isAd()) {
        queries = {
          event_source_type: EVENT_SOURCES.NATURAL
        }
      }
      replaceUrl(queries)
    },
    getComponent(styleId) {
      return stylesMap[styleId].component
    },
    getComponentName(styleId) {
      return stylesMap[styleId].componentName
    },
    blockStyle(block) {
      const { data = {} } = block || {}
      const { bg = '', height } = data || {}
      const style = {
        height: this.length(height),
        backgroundPosition: 'center',
        backgroundSize: 'cover'
      }

      if (/^http(s)?:\/\//.test(bg)) {
        style.backgroundImage = `url(${webpSafe(bg)})`
      } else {
        style.backgroundColor = bg
      }

      return style
    },
    layoutStyles(layout, fixed, options) {
      const { isFixedTopNormal } = options || {}
      const styles = {
        width: this.length(layout.width),
        height: this.length(layout.height)
      }

      if (fixed === 'top' || fixed === 'bottom') {
        let top = layout.top
        top += isFixedTopNormal && this.fixedTopJoint ? 210 : 0 // 如果该吸顶组件上方存在拼接视频，则预留空间

        styles[fixed] = this.length(top)
        styles.left = this.length(layout.left)
      } else if (fixed === 'left' || fixed === 'right') {
        styles.top = this.length(layout.top)
        styles[fixed] = this.length(layout.left)
      } else {
        styles.top = this.length(layout.top)
        styles.left = this.length(layout.left)
      }

      return styles
    },
    length(value) {
      // 尺寸不变，降级h5使用缩放
      return this.pc(value)
    },
    vw(value) {
      if (isNaN(value)) {
        return ''
      }
      return `${((value / 375) * 100).toFixed(5)}vw`
    },
    pc(value) {
      if (isNaN(value)) {
        return ''
      }
      return `${value * 1}px`
    },
    abTestForCvr() {
      // web cvr提升专项
      if (this.page.blocks.length === 0 || !window._globalExp?.web_cvr_up) {
        // 数据尚未获取
        return
      }
      this.$reportUI(MINI_web_cvr_up, { ...window._globalExp })
      // 隐藏block实验
      if (
        window._globalExp?.block_hiden &&
        window._globalExp?.block_hiden.page_id?.includes(pageId)
      ) {
        for (const id of window._globalExp.block_hiden.block_ids) {
          const index = this.page.blocks.findIndex((b) => b.id === id)
          if (index !== -1) {
            this.page.blocks.splice(index, 1)
          }
        }
      }
      // 隐藏component实验
      if (
        window._globalExp?.comp_hiden &&
        window._globalExp?.comp_hiden.page_id?.includes(pageId)
      ) {
        for (const block of this.page.blocks) {
          for (let i = 0; i < block.components.length; i++) {
            const component = block.components[i]
            if (window._globalExp?.comp_hiden.comp_ids?.includes(component.id)) {
              block.components.splice(i, 1)
              i -= 1
            }
          }
        }
      }
      // web颜色实验
      if (
        window._globalData?.colorDiff?.color &&
        window._globalExp?.color &&
        window._globalExp?.color.color_diff &&
        window._globalExp?.color.industry_ids?.includes(window._globalData.industryId) &&
        window._globalData.colorDiff?.color < window._globalExp?.color.color_diff &&
        window._globalData.colorDiff?.color > (window._globalExp?.color.color_diff_min || 0)
      ) {
        const color = window._globalExp.color
        if (color.brightness) {
          this.abTestColor.filter += `brightness(${color.brightness}) `
        }
        if (color.saturation) {
          this.abTestColor.filter += `saturate(${color.saturation}) `
        }
        if (color.contrast_ratio) {
          this.abTestColor.filter += `contrast(${color.contrast_ratio})`
        }
      }
      // 图像替换实验
      if (window._globalExp?.img) {
        // 图片组件替换
        if (window._globalExp?.img.page_id?.includes(pageId)) {
          const imgs = window._globalExp.img.img_replace_list
          const imgIds = imgs.map((img) => img.comp_id) || []
          for (const block of this.page.blocks) {
            for (const component of block.components) {
              if (imgIds.includes(component.id)) {
                const index = imgIds.indexOf(component.id)
                component.data.content.image = imgs[index].url
              }
            }
          }
        }
        // 背景图片替换
        if (window._globalExp?.img.bg_page_id?.includes(pageId)) {
          const imgs = window._globalExp.img.bg_replace_list
          const imgIds = imgs.map((img) => img.comp_id) || []
          for (const block of this.page.blocks) {
            if (imgIds.includes(block.id)) {
              const index = imgIds.indexOf(block.id)
              block.data.bg = imgs[index].url
            }
          }
        }
      }
    },
    analyzeComp() {
      let hasForm = false
      let hasDownload = false
      let hasImageDownload = false
      let hasNonImageDownload = false
      let firstForm = null

      const texts = []
      const images = []
      const videos = []
      const buttons = []
      const downloads = []
      const forms = []
      const weixins = []
      const goods = []
      const fixedTop = []
      const fixedBottom = []

      for (const block of this.page.blocks) {
        for (const component of block.components) {
          const name = component.name
          const typeName = this.getComponentName(name)

          if (typeName.includes('文本')) {
            texts.push(name)
          }
          if (typeName.includes('图片')) {
            images.push(name)
          }
          if (typeName.includes('视频')) {
            videos.push(name)
          }
          if (typeName.includes('按钮')) {
            buttons.push(name)
          }
          if (typeName.includes('表单')) {
            forms.push(name)
            hasForm = true
            if (!firstForm) {
              firstForm = component
            }
          }
          if (typeName.includes('下载')) {
            downloads.push(name)
            hasDownload = true
            if (typeName.includes('图片下载')) {
              hasImageDownload = true
            } else {
              hasNonImageDownload = true
            }
          }
          if (typeName.includes('微信')) {
            weixins.push(name)
          }
          if (typeName.includes('商品')) {
            goods.push(name)
          }
          if (name.includes('fixed-top')) {
            fixedTop.push(name)
          }
          if (name.includes('fixed-bottom')) {
            fixedBottom.push(name)
          }
        }
      }
      this.analysis = {
        hasDownload,
        hasOnlyImageDownload: hasImageDownload && !hasNonImageDownload,
        hasForm,
        firstForm
      }

      const analysis = {
        block: (this.page.blocks || []).filter((b) => b.name === 'block').length,
        text: texts.length,
        image: images.length,
        video: videos.length,
        button: buttons.length,
        download: downloads.length,
        form: forms.length,
        weixin: weixins.length,
        goods: goods.length,
        top: fixedTop[0] || 0,
        bottom: fixedBottom[0] || 0
      }

      // 统计字段平铺，后向兼容商业前端统计平台
      const analysisFlat = {}
      Object.keys(analysis).forEach((k) => {
        analysisFlat[`a_${k}`] = analysis[k]
      })
      this.analysisReport = {
        analysis,
        ...analysisFlat
      }
    },
    getQueries() {
      let res = {}
      const search = (window.location.search || '')
        .substr(1)
        .replace('sycp_avid=__AVID__', 'sycp_avid=')
        .replace('sycp_cid=__CID__', 'sycp_cid=')
        .replace('sycp_cover=__COVER__', 'sycp_cover=')
        .replace('sycp_is_model=__IS_MODEL__', 'sycp_is_model=')
      const query = qs.parse(search) || {}
      const { sycp_avid, sycp_cid, sycp_cover, sycp_is_model } = query
      // 是创意联投
      if (sycp_avid && +sycp_is_model === 2) {
        res = {
          sycp_avid,
          sycp_cid,
          sycp_cover,
          sycp_is_model
        }
      }
      if (window.alita) {
        const pageInfo = window.alita.getPageInfo()
        res.mid = pageInfo.mid || ''
        res.buvid = pageInfo.buvid || ''
      }
      if (!isUndefined(query.preview_key)) {
        res.validate_key = query.preview_key
      }
      if (!isUndefined(query.preview_mode)) {
        res.preview_mode = query.preview_mode
      }
      return res
    },
    getLandingPageData() {
      const pageData = window?._extraData?.card?.imax_landing_page_v2 || ''
      if (window && window._extraData && pageData) {
        const pageInfo = JSON.parse(pageData)
        const { template_style, config } = pageInfo || {}
        if (
          (Number(template_style) === 401 || Number(template_style) === 501) &&
          config &&
          config.length > 0
        ) {
          this.setPageInfo({
            data: pageInfo
          })
          this.gameDownloadHandler({
            data: pageInfo
          })
          return
        }
      }
      this.getPageRequest()
    },
    iosAppPreLoad(res) {
      if (getQuery('is_gaoneng_preload')) {
        return
      }
      const downloadWhitelist = res?.data.download_whitelist || []
      const JSB_IOS_APP_PRELOAD = 'cm.preloadAppStore'
      const { ios_preload = 0 } = window._globalExp || {}
      if (+ios_preload !== 1) {
        return
      }

      if (!this.isIosAppPreLoad && isIOS && downloadWhitelist.length > 0) {
        const downloadList = downloadWhitelist.filter((item) => {
          const { url = '' } = item
          return /^http(s)?:\/\/(itunes|apps).apple.com\/[^\s]*app(\/[^\s]*)*\/id\d+(?=.*)/.test(
            url
          )
        })
        if (downloadList.length > 0) {
          const iosUrl = downloadList[0].url || ''
          callJSB({
            method: JSB_IOS_APP_PRELOAD,
            callback: (data) => {
              if (+data.code === 0) {
                this.$reportUI(MINI_ios_app_preload_success)
              } else {
                this.$reportUI(MINI_ios_app_preload_error, { reason: +data.code })
              }
            },
            data: {
              preload_info: { url: iosUrl }
            }
          })
        }
        this.isIosAppPreLoad = true
      }
    },
    refresh() {
      if (!pageId || (isPreload() && !isPreRender())) {
        return
      }
      this.loading = true
      // 默认先通过JSB从卡信息拿数据
      const JSB_CARD_INFO = 'cm.getExtraInfo'
      callJSB({
        method: JSB_CARD_INFO,
        callback: (res) => {
          try {
            const { code, data = {} } = res || {}
            if (code === 0) {
              const cardData = JSON.parse(data) || {}
              const pageInfo = JSON.parse(cardData?.card?.imax_landing_page_v2) || {}
              const { template_style, config } = pageInfo || {}
              if (
                (Number(template_style) === 401 || Number(template_style) === 501) &&
                config &&
                config.length > 0
              ) {
                this.setPageInfo({
                  data: pageInfo
                })
              } else {
                this.$reportUI(MINI_jsb_error, {
                  jsb_name: JSB_CARD_INFO,
                  err: `data is error: ${JSON.stringify(pageInfo)}`
                })
                this.getPageRequest()
              }
            }
          } catch (e) {
            this.$reportUI(MINI_jsb_error, { jsb_name: JSB_CARD_INFO, err: JSON.stringify(e) })
            this.getPageRequest()
          }
        },
        onNotSupport: () => {
          this.getPageRequest()
        },
        reportResHandler: (res) => {
          try {
            const cardData = JSON.parse(res.data) || {}
            const url = JSON.parse(cardData.jump_url || '{}')
            return `use jsb data: ${url}`
          } catch (e) {
            console.log(e)
          }
        }
      })
    },
    getPageRequest() {
      this.$request
        .get(`v1/landing_pages/${pageId}`, { ...this.getQueries() })
        .then((res) => {
          this.setPageInfo(res)
          this.gameDownloadHandler(res)
        })
        .catch((err) => {
          console.log(err)
          this.$toast('获取页面信息失败')
          this.loading = false
          this.$reportUI(MINI_page_load_fail, { err_reason: 1 })
          this.$reportWarn(WARN_page_load_fail, { err_reason: 1 })
        })
    },
    setPageInfo(res) {
      // if (JSON.stringify(this.page) !== '{}') {
      //   return
      // }
      const { template_style = 401 } = res?.data || {}
      this.templateStyle = template_style

      window._globalData = {
        ...window._globalData,
        customerName: res?.data?.privacy_name,
        isDownloadCheckOn: res?.data?.download_button_over_limit_hint,
        // isDownloadIllegal: res?.data?.is_download_button_over_limit
        // 弹窗暂时全开
        isDownloadIllegal: 1,
        // 是否开启临近门店功能 0： 不开启 1：开启
        isShowDistanceLbs: res?.data?.account_label_config?.is_show_distance_lbs || 0,
        // 是否开启ip兜底逻辑 0： 不开启 1：开启
        useIpLocation: res?.data?.account_label_config?.use_ip_location || 0,
        // 计算出的color数据
        colorDiff: {
          lum: res?.data?.lum || 0,
          grey: res?.data?.grey || 0,
          color: res?.data?.color || 0
        },
        autoCreateAccPage: res?.data?.auto_create_acc_page || false,
        industryId: res?.data?.commerce_category_first_id || ''
      }
      // 全局实验组
      window._globalExp = this.experiments = {
        ...window._globalExp,
        form_button_hit: res.data?.form_button_hit, // 置底表单按钮开关
        h5_download_hit: res.data?.h5_download_hit, // 置底下载按钮
        wx_copy_hit: res.data?.wechat_is_manual // 微信复制
      }

      this.page = (res?.data?.config || [])[0] || {}

      this.loading = false
      this.abTestForCvr()
      this.updatePage()

      if (
        res.data?.customer_acq_link_ids?.length &&
        getQuery('track_id') &&
        getQuery('track_id') !== '__TRACKID__'
      ) {
        // 请求获客链接对应的宏id
        this.$request
          .post('v1/work_wechat/save/track/id', { track_id: getQuery('track_id') })
          .then((res) => {
            window._globalData.trackId = res.data
          })
          .catch((err) => {
            this.$reportUI(MINI_work_wechat_id_fail, err)
            this.$reportWarn(WARN_page_weixin_tackid_fail, err)
          })
      }

      if (this.build && this.supportDownload) {
        this.androidDownloadHandler(res)
      } else {
        this.androidDownloadHandlerCallback = res?.data?.games?.length
          ? this.gameDownloadHandler
          : this.androidDownloadHandler
        this.androidDownloadHandlerCallbackData = res
      }
      // ios应用商店预加载逻辑
      this.iosAppPreLoad(res)

      if (JSON.stringify(this.page) === '{}') {
        this.$reportUI(MINI_page_load_fail, { err_reason: 0 })
        this.reportTechError(WARN_page_load_fail, { err_reason: 0 })
      } else {
        this.analyzeComp()
        if (!this.isSetPageInfo) {
          this.$reportUI(MINI_page_load_success, { ...window._globalExp, ...this.analysisReport })
        }
      }

      // 有表单时页面渲染完成呢时机依赖于表单加载完成，无表单时则立刻渲染完成
      const forms = this.analysisReport?.analysis?.form

      if (res?.data?.form_ids?.length === 0 && this.touchUtils) {
        this.touchUtils.touchUnRegister()
        this.touchUtils = null
      }

      if (forms > 0) {
        window._globalData.formCount = forms
      } else {
        this.$nextTick(() => {
          !this.isSetPageInfo &&
            this.$reportUI(MINI_page_rendered, {
              ...window._globalExp,
              ...getFormButtonExpInfo()
            })
        })
      }
      this.isSetPageInfo = true
      this.currentEnv = getEnv(res.data?.env)
      // 表单数据获取失败兜底
      this.templateH5Reveal()
    },
    initTouchUtils() {
      const { is_touch_keywords: isTouchKeywords = 0 } = window._globalExp || {}
      // && isTouchKeywords
      if (!this.isTemplateH5 && isTouchKeywords) {
        this.touchUtils = new TouchUtils()
        this.touchUtils.touchRegister()
      }
    },
    updatePage() {
      const { title, page_bg_color: bgColor = '', page_bg_url: bgImage = '' } = this.page || {}

      document.title = title || '高能建站'

      if (this.isMobile) {
        return
      }

      if (bgImage) {
        document.body.style.backgroundImage = `url("${bgImage}")`
      } else {
        document.body.style.backgroundColor = bgColor
        document.body.style.minHeight = '100vh'
      }
    },
    getReportInfo(component = {}, stringify = true) {
      const info = {
        id: component.id,
        item_id: component.id,
        item_name: component.name
      }
      return stringify ? JSON.stringify(info) : info
    },
    androidDownloadHandler(res) {
      if (!this.supportDownload) return

      // 应用包取白名单第一个
      const firstWhite = (res?.data.download_whitelist || [])[0] || {}
      if (firstWhite.url) {
        // 普通下载
        this.androidDownloadUrl = firstWhite.url
        set(this.autoFillDownloadComp, 'data.content.behavior.android.url', this.androidDownloadUrl)
        this.registerDownload()
      }
    },
    gameDownloadHandler(res) {
      if (!this.supportDownload) return
      if (res?.data.games?.length && !this.gameDownloadData?.game_id) {
        // 游戏app下载
        this.gameDownloadData = {
          type: 5,
          game_id: res?.data.games[0]?.game_base_id,
          game_channel_id: res.data.games[0].channel_id,
          game_monitor_param: getQuery('source') || '', // 游戏监控字段（仅在type=5时有值）
          game_channel_extra: '', // 游戏渠道额外字段，目前为空
          game_sourcefrom: getQuery('sourceFrom') || '' // 来源
        }
        this.registerDownload(true)
      }
    },
    registerDownload(isGame = false) {
      if (!this.supportDownload) return

      try {
        const self = this
        const data = isGame
          ? { button: this.gameDownloadData, type: 5 }
          : {
              url: self.androidDownloadUrl
            }
        callJSB({
          method: 'cm.registerDownloadListener',
          data,
          callback: function (res) {
            // 第一次调用返回code，确认调用是否生效
            const { code } = res
            let downloadable = '1'
            let status, percent
            // 生效后，返回进度
            if (isGame) {
              status = res?.data?.status || res?.status
              percent = res?.data?.percent || res?.percent
              downloadable = res?.data?.downloadable || res?.downloadable
            } else {
              status = res?.status
              percent = res?.percent
            }

            if (code === errorEnum.UNKNOWN) {
              if (self.retryDownloadRegister < 3) {
                self.retryDownloadRegister++
                self.registerDownload(isGame)
              } else {
                isGame &&
                  self.$reportUI(MINI_game_app_download_error, {
                    reason: errorMsgEnum[errorEnum.UNKNOWN]
                  })
                self.unInit()
              }
              return
            }
            if (code !== errorEnum.SUCCESS) {
              isGame &&
                self.$reportUI(MINI_game_app_download_error, {
                  reason: errorMsgEnum[code]
                })
            }

            let text = self.download?.text
            const action = () => {
              self.actionDownload(isGame)
            }
            if (downloadable + '' === '1') {
              if (status === undefined || percent === undefined) return
              switch (status) {
                case downloadStatus.START:
                case downloadStatus.DOWNLOAD:
                  text = `已下载${percent}%`
                  break
                case downloadStatus.PAUSED:
                case downloadStatus.ERROR:
                  text = '继续下载'
                  break
                case downloadStatus.COMPLETE:
                  text = '立即安装'
                  break
                case downloadStatus.INSTALLED:
                  text = '立即打开'
                  break
                default:
                  break
              }
            } else {
              text = res.data?.text || res?.text || ''
            }
            self.download = {
              text,
              action,
              isGame,
              canTriggerDownload: [
                downloadStatus.PREPARE,
                downloadStatus.WAIT,
                downloadStatus.PAUSED
              ].includes(status),
              canTriggerDownloadButton: [downloadStatus.PREPARE, downloadStatus.WAIT].includes(
                status
              )
            }
          }
        })
      } catch (e) {
        isGame &&
          this.$reportUI(MINI_game_app_download_error, {
            reason: '注册jsb调用失败'
          })
        console.log(e)
        this.$reportWarn(WARN_page_download_fail)
      }
    },
    actionDownload(isGame = false) {
      // 包含开始下载、暂停下载、安装等行为。端上内部判断，web通过回调状态执行对应操作
      if (!this.supportDownload) return
      this.$reportUI(MINI_item_cvr_click, {
        event_type: 'downloadEvent'
      })

      try {
        const self = this
        const data = isGame
          ? { button: this.gameDownloadData, type: 5 }
          : {
              url: self.androidDownloadUrl
            }
        callJSB({
          method: 'cm.actionDownload',
          data,
          callback: function (res) {
            const { code } = res
            if (code !== errorEnum.SUCCESS) {
              self.$toast(errorMsgEnum[code])
              isGame &&
                self.$reportUI(MINI_game_app_download_error, {
                  reason: errorMsgEnum[code]
                })
            } else {
              isGame &&
                self.$reportUI(MINI_game_app_download_success, {
                  button: {
                    ...self.gameDownloadData
                  }
                })
            }
          }
        })
      } catch (e) {
        isGame &&
          this.$reportUI(MINI_game_app_download_error, {
            reason: '下载jsb调用失败'
          })
      }
    },
    changeShowSixElements() {
      const sixElementsRef = this.$refs.sixElements
      if (!sixElementsRef) return

      const isAlwaysMode = sixElementsRef.isAlwaysMode
      if (isAlwaysMode) {
        this.showSixElementsAlways = true
        const containerTop = getOffsetTop(document.querySelector('.page-container'))
        const curScrollTop = document.documentElement.scrollTop || 0
        if (curScrollTop > containerTop) {
          this.showSixElements = false
        } else {
          this.showSixElements = true
        }
      }
    },
    getlandingPageInfo() {
      this.getAdcb()
      this.getExtraInfo()
    },
    getExtraInfo() {
      if (!pageId || (isPreload() && !isPreRender())) {
        return
      }
      const self = this
      const JSB_EXTRA_INFO = 'cm.getExtraInfo'
      callJSB({
        method: JSB_EXTRA_INFO,
        callback: (res) => {
          try {
            const { code, data = {} } = res || {}
            if (code === 0) {
              const extraData = JSON.parse(data) || {}
              window._extraData = extraData
              this.isGetExtraData = true
              const abtest = JSON.parse(extraData.abtest || '{}')
              const gaonengABTest = JSON.parse(abtest?.gaoneng_abtest || '{}') || {}
              delete abtest.gaoneng_abtest
              window._globalExp = this.experiments = {
                ...window._globalExp,
                ...gaonengABTest,
                ...abtest,
                b_login_phone: 1
              }
              this.getLandingPageData()
              this.initTouchUtils()
            }
          } catch (e) {
            self.$reportUI(MINI_jsb_error, { jsb_name: JSB_EXTRA_INFO, err: JSON.stringify(e) })
            window._extraData = {}
            this.getPageRequest()
          }
          this.isExtraInfoLoaded = true
        },
        onNotSupport: () => {
          this.isExtraInfoLoaded = true
          this.getPageRequest()
        },
        reportResHandler: (res) => {
          try {
            const extraData = JSON.parse(res.data) || {}
            const abtest = JSON.parse(extraData.abtest || '{}')
            return abtest?.gaoneng_abtest
          } catch (e) {
            console.log(e)
          }
        }
      })
    },
    getAdcb() {
      if (!pageId || (isPreload() && !isPreRender())) {
        return
      }
      const JSB_EXTRA_INFO_AD_CB = 'cm.getFromCardInfo'
      callJSB({
        method: JSB_EXTRA_INFO_AD_CB,
        callback: (res) => {
          try {
            const { code, data = {} } = res || {}
            if (code === 0) {
              const adInfo = data.ad_cb || ''
              window.ad_cb = adInfo
            }
          } catch (e) {
            this.$reportUI(MINI_jsb_error, {
              jsb_name: JSB_EXTRA_INFO_AD_CB,
              err: JSON.stringify(e)
            })
          }
        }
      })
    },
    detectPageSize() {
      const erd = elementResizeDetector()
      erd.listenTo(document.documentElement, (ele) => {
        this.$nextTick(() => {
          this.appScrollHeight = ele.scrollHeight
        })
      })
    },
    isTemplateH5FormLoaded() {
      this.templateH5FormLoaded = true
    },
    templateH5Reveal() {
      setTimeout(() => {
        this.templateH5FormLoaded = true
      }, 1000)
    }
  }
}
</script>

<style lang="less" scoped>
body {
  margin: 0;
}
#app {
  width: 375px;
  margin: 0 auto;
  user-select: none;
  background-color: #ffffff;
}
.auto-block {
  position: relative;
  width: 100%;
}

.block {
  position: relative;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
}
.fixed-block {
  position: fixed;
  width: 375px;
  top: 0;
}
.auto-layout {
  // position: absolute;
}
.layout {
  position: absolute;
}
.fixed-layout {
  position: absolute;
  z-index: 1000;
}
.fixed-top,
.fixed-bottom {
  position: fixed;
  width: 375px;
  height: 0;
  z-index: 1000;
}
.fixed-top {
  top: 0;
}
.fixed-bottom {
  bottom: 0;
}
.fixed-left,
.fixed-right {
  position: fixed;
  width: 0;
  height: 0;
  z-index: 1000;
}
.fixed-right {
  transform: translateX(375px);
}
.download-button-wrapper {
  width: 375px;
  height: 60px;
  padding: 8px 12px;
}
.ios-safe {
  width: 100%;
  height: 34px;
  background-color: white;
}
.env-fixed {
  position: fixed;
  min-width: 30px;
  line-height: 30px;
  background-color: #21b226;
  text-align: center;
  z-index: 99999;
  left: 8px;
  bottom: 8px;
  border-radius: 4px;
  color: white;
  font-size: 14px;
  padding: 0 10px;
}
.page-container {
  position: relative;
  width: 100%;
}
.pre-load-mask {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10000;
  width: 100vw;
  height: 100vh;
  background: #ffffff;
}
</style>
